.saying-avatar {
  @apply border border-orange flex items-center justify-center rounded-full;
  background: linear-gradient(180deg, #652222 0%, #1D1E21 100%);
  width: 128px;
  height: 128px;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  img {
    @apply rounded-full;
    width: 110px;
    height: 110px;
  }
}

.saying-card {
  width: 571px;
  &:hover {
    .saying-avatar {
      transform: scale(1.15);
    }
  }
}

.trust-container {
  background: radial-gradient(93.39% 154.47% at 49.41% 10.53%, rgba(236, 43, 43, 0.4) 0%, rgba(0, 0, 0, 0) 100%), #501818;
}

@media (max-width: 767px) {
  .saying-avatar {
    width: 84px;
    height: 84px;
    img {
      width: 73px;
      height: 73px;
    }
  }
  .saying-card {
    width: 100%;
  }
}
